@import '../mixins';

.textFieldContainer {
  // min-width: 268px;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 14px;
  height: 56px;
  padding: 0 18px;
  background-color: $--card-main !important;
  border-radius: 48px;
  box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    flex-grow: 1;
    color: $--main-text;
    outline: none;
    border: none;
    &::placeholder {
      color: $--sec-text;
      opacity: 1;
    }
  }
}

.selectFieldContainer {
  min-width: 255px;
  //   max-width: 500px;
  border-radius: 48px;
  max-width: 100%;
  height: 56px;
  box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;

  @include mobile {
    min-width: max-content;
    height: 40px;
  }
  &_menu {
    background: $--card-main !important;
    box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
    border-radius: 24px !important;
    overflow: hidden;
    padding: 12px 0;
    z-index: 9999999 !important;
  }
  &_select {
    width: 100%;
    padding: 0 18px;
    display: flex;
    align-items: center;
    gap: 14px;
    height: 56px;
    background-color: $--card-main !important;
    border-radius: 48px !important;
    border: none !important;
    box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
    @include mobile {
      height: 40px;
    }
  }
  &_selectFocused {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 0 18px;
    background-color: $--card-main !important;
    border-radius: 48px !important;
    border: 1px solid $--primary !important;
    box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
  }
  &_optionMenuSelected {
    height: 44px;
    padding-left: 25px;
    color: $--primary !important;
    cursor: pointer !important;
    font-weight: 400;
    padding-left: 25px !important;
    font-size: 16px;
    line-height: 20px;
    background-color: transparent !important;
  }
  &_optionMenuFocused {
    font-weight: 400;
    padding-left: 25px;
    font-size: 16px;
    cursor: pointer !important;
    padding-left: 25px !important;
    line-height: 20px;
    height: 44px;
    background-color: $--light-red !important;
  }
  &_optionMenu {
    font-weight: 400;
    cursor: pointer !important;
    padding-left: 25px !important;
    font-size: 16px;
    line-height: 20px;
    height: 44px;
  }
}
.DselectFieldContainer {
  min-width: 255px;
  //   max-width: 500px;
  border-radius: 48px;
  max-width: 100%;
  height: 56px;
  // box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;

  @include mobile {
    min-width: max-content;
    height: 40px;
  }
  &_menu {
    background: $--card-main !important;
    box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
    border-radius: 24px !important;
    overflow: hidden;
    padding: 12px 0;
    z-index: 9999999 !important;
  }
  &_select {
    width: 100%;
    padding: 0 18px;
    display: flex;
    align-items: center;
    gap: 14px;
    height: 56px;
    background-color: $--card-main !important;
    border-radius: 48px !important;
    border: none !important;
    box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
    @include mobile {
      height: 40px;
    }
  }
  &_selectFocused {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 0 18px;
    background-color: $--card-main !important;
    border-radius: 48px !important;
    border: 1px solid $--primary !important;
    box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
  }
  &_optionMenuSelected {
    height: 44px;
    padding-left: 25px;
    color: $--primary !important;
    cursor: pointer !important;
    font-weight: 400;
    padding-left: 25px !important;
    font-size: 16px;
    line-height: 20px;
    background-color: transparent !important;
  }
  &_optionMenuFocused {
    font-weight: 400;
    padding-left: 25px;
    font-size: 16px;
    cursor: pointer !important;
    padding-left: 25px !important;
    line-height: 20px;
    height: 44px;
    background-color: $--light-red !important;
  }
  &_optionMenu {
    font-weight: 400;
    cursor: pointer !important;
    padding-left: 25px !important;
    font-size: 16px;
    line-height: 20px;
    height: 44px;
  }
}

.sselectFieldContainer {
  width: 156px;
  //   max-width: 500px;
  border-radius: 48px;
  max-width: 100%;
  height: 28px;

  &_menu {
    background: $--card-main !important;
    // box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
    border-radius: 24px !important;
    overflow: hidden;
    padding: 12px 0;
    z-index: 9999999 !important;
  }
  &_select {
    width: 100%;
    padding: 0 18px;
    display: flex;
    align-items: center;
    gap: 14px;
    height: 28px;
    background-color: $--card-main !important;
    border-radius: 48px !important;
    border: none !important;
  }
  &_selectFocused {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 0 18px;
    background-color: $--card-main !important;
    border-radius: 48px !important;
    border: 1px solid $--primary !important;
    box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
  }
  &_optionMenuSelected {
    height: 44px;
    padding-left: 25px;
    color: $--primary !important;
    cursor: pointer !important;
    font-weight: 400;
    padding-left: 25px !important;
    font-size: 16px;
    line-height: 20px;
    background-color: transparent !important;
  }
  &_optionMenuFocused {
    font-weight: 400;
    padding-left: 25px;
    font-size: 16px;
    cursor: pointer !important;
    padding-left: 25px !important;
    line-height: 20px;
    height: 44px;
    background-color: $--light-red !important;
  }
  &_optionMenu {
    font-weight: 400;
    cursor: pointer !important;
    padding-left: 25px !important;
    font-size: 16px;
    line-height: 20px;
    height: 44px;
  }
}

.focused {
  border: 1px solid $--primary;
}
